<template>
	<div>
		<h2 class="mb-5">Аукцион свободных мест</h2>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			class="elevation-1 request_list">
			<template v-slot:item.actions="{ item }">
				<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						dark
						v-bind="attrs"
						v-on="on">
						Выбрать
					</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="(child, index) in item.childs" :key="index" @click="selectSeat(item.id, child.rqpId)">
							<v-list-item-title>{{ child.full_name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	mounted() {
		this.getData();
	},

	data: () => ({
		requestHeaders: [],
		requestList: [],
		isLoading: true,
	}),

	methods: {
		handleClick(item) {
			console.log(item);
			//this.$router.push({ path: '/parent/queue-request/'+item.id });
		},
		async getData() {
			this.isLoading = true;
			await this.$http.get('/auction').then((response) => {
				this.requestHeaders = response.data.data.seatsList.headers;
				this.requestHeaders.push({value: 'actions', sortable: false, align: 'end'});
				this.requestList = response.data.data.seatsList.list;
			}).catch(() => {});
			this.isLoading = false;
		},
		async selectSeat(seatId, childId) {
			await this.$http.post('/auction/choose', {
                "pfsId": seatId,
                "rqpId": childId
			}).then((response) => {
				if(response.data.status == 'success') {
					this.getData();
				}
			}).catch(() => {});
		}
	}
}
</script>

<style scoped>

</style>